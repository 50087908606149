/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-expressions */
import React from 'react';
import PropTypes from 'prop-types';
import { useRootTranslation, useSnackbar } from 'utils/hooks';
import { updateFileAttachmentCall, createFileAttachmentCall } from 'redux-core/overview/services';
import { FILES_TYPES_OPTIONS } from 'utils/constants';
import BaseFilesDrawer from '../BaseFilesDrawer';
import ExtraFields from './ExtraFields';

const TrackFilesDrawer = ({
  name,
  onClose,
  payload: {
    clearanceBundle,
    rightsOwner,
    productionTitle,
    hideBackButton,
    onSubmit,
    track,
    productionId,
    file,
    readOnlyProps,
    clearanceBundled,
    divisionId,
  },
  ...props
}) => {
  const showSnackbar = useSnackbar();
  const t = useRootTranslation('files.notification');

  const handleOnSubmit = async (values) => {
    const payload = {
      ...values,
      productionId,
      trackId: track.id,
      qclearTrackId: track.id,
    };
    if (payload.id) {
      await updateFileAttachmentCall(payload);
      showSnackbar({ message: t('update') }, { name: values.fileName });
    } else {
      await createFileAttachmentCall(payload);
      showSnackbar({ message: t('create') });
    }

    onSubmit && (await onSubmit(payload));
    onClose();
  };

  return (
    <BaseFilesDrawer
      hideBackButton={hideBackButton}
      productionTitle={productionTitle}
      {...props}
      name={name}
      initialValues={{ ...file, qclearTrackId: track && track.id }}
      onClose={onClose}
      onSubmit={handleOnSubmit}
      title={track?.title}
      fileTypes={FILES_TYPES_OPTIONS}
    >
      <ExtraFields
        track={track}
        readOnlyProps={readOnlyProps}
        clearanceBundled={clearanceBundled}
        divisionId={divisionId}
        rightsOwner={rightsOwner}
        clearanceBundle={clearanceBundle}
      />
    </BaseFilesDrawer>
  );
};

TrackFilesDrawer.propTypes = {
  name: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  payload: PropTypes.shape({
    hideBackButton: PropTypes.bool,
    onSubmit: PropTypes.func,
    productionId: PropTypes.number,
    productionTitle: PropTypes.string,
    track: PropTypes.object,
    readOnlyProps: PropTypes.array,
    clearanceBundled: PropTypes.bool,
  }).isRequired,
};

TrackFilesDrawer.defaultProps = {
  payload: {
    hideBackButton: true,
  },
};
export default TrackFilesDrawer;
